import * as React from 'react';

import styled, { createGlobalStyle } from 'styled-components';
import { ThemeProvider, audiLightTheme, audiDarkTheme } from '@audi/audi-ui-react';

import { Theme, AssetTypeEnum, StageProps, Mode } from '../../../types';

import StageLegal from '../stage-legal/StageLegal';
import StageButtons from '../stage-content/StageButtons';
import { useTracking } from '../../hooks/useTracking';

import StageVideo from '../stage-video/StageVideo';
import StageImage from '../stage-image/StageImage';
import StageShader from '../stage-shader/StageShader';
import StageContent from '../stage-content/StageContent';
import { useIsMobile } from '../../hooks/useIsMobile';

// this is needed to combat the infitine image height increasing problem in universal editor
const GlobalStyle = createGlobalStyle`
  html.adobe-ue-edit .mediaItem {
    height: 800px;
  }
`;

const StageContainerWrap = styled.div<{ theme: Theme }>`
  background-color: ${({ theme }) => (theme === Theme.Light ? 'white' : 'black')};
  margin: 0;
  position: relative;
`;

const Block = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 1920px;

  & a.audi-j-footnote-reference:hover {
    color: inherit;
  }
`;
export type StageContainerContent = StageProps;

interface StageContainerProps {
  content: StageContainerContent;
}

const StageContainer: React.FC<StageContainerProps> = (props: StageContainerProps) => {
  const { content } = props;
  const { asset, appearance, text, buttons, consumptionAndEmissions, disclaimers } = content;
  const { mode = Mode.SMALL, theme } = appearance;

  const ref = React.useRef(null);
  const isMobile = useIsMobile();
  const tracking = useTracking(ref, content, {
    implementer: 37,
  });

  React.useEffect(() => {
    tracking.sendReadyEvent();
    tracking.registerImpressionTracking();
  }, []);

  return (
    <ThemeProvider theme={theme === Theme.Light ? audiLightTheme : audiDarkTheme}>
      <GlobalStyle />
      <StageContainerWrap ref={ref} theme={theme}>
        <Block>
          {asset.assetType === AssetTypeEnum.Video && <StageVideo mode={mode} asset={asset} />}
          {asset.assetType === AssetTypeEnum.Image && <StageImage mode={mode} asset={asset} />}
          <StageShader appearance={appearance} assetType={asset.assetType}>
            <StageContent
              appearance={appearance}
              text={text}
              buttons={isMobile && mode === Mode.SMALL ? undefined : buttons}
              tracking={tracking}
              isMobile={isMobile}
              hasConsumptionAndEmission={Boolean(consumptionAndEmissions?.length)}
            />
          </StageShader>
        </Block>
        <Block>
          {isMobile && mode === Mode.SMALL && (
            <StageButtons
              appearance={appearance}
              buttons={buttons}
              tracking={tracking}
              isMobile={isMobile}
            />
          )}

          <StageLegal consumptionAndEmissions={consumptionAndEmissions} disclaimers={disclaimers} />
        </Block>
      </StageContainerWrap>
    </ThemeProvider>
  );
};

export default StageContainer;
